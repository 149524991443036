<template>
    <div class="container">

        <div class="row">

            <div class="col-lg-12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                        >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
            </div>

            <div class="col-lg-12">
                <div class="card elevation-5">
                    <div class="card-body">
                        <div class="row">
                            <!-- <div class="col-lg-2">
                                <v-autocomplete v-model="officeModel" :items="officeLists" default="" item-value="office_id" item-text="office" label="Branch Office" clearable dense prepend-icon="mdi-checkbox-multiple-blank-circle" return-object></v-autocomplete>
                            </div> -->
                            <div class="col-lg-3">
                                <v-autocomplete v-model="provinceModel" :items="provinceLists" default="" item-value="province" item-text="province" label="Province" @change="(event) => provinceChange(event)" dense prepend-icon="mdi-checkbox-multiple-blank-circle"></v-autocomplete>
                            </div>
                            <div class="col-lg-3">
                                <v-autocomplete v-model="cityModel" :items="cityLists" default="" item-value="city" item-text="city" label="City" @change="(event) => cityChange(event)" dense prepend-icon="mdi-checkbox-multiple-blank-circle"></v-autocomplete>
                            </div>
                            <div class="col-lg-3">
                                <v-autocomplete v-model="districtModel" :items="districtLists" default="" item-value="districts" item-text="districts" label="District" dense prepend-icon="mdi-checkbox-multiple-blank-circle"></v-autocomplete>
                            </div>
                            <div class="col-lg-3">
                                <div class="d-flex justify-content-start mt-3">
                                    <v-btn class="mr-2" color="orange" elevation="2" small rounded @click="clearAll()" dark>
                                        Clear
                                    </v-btn>
                                    <v-btn color="primary" elevation="2" small rounded @click="getData()">
                                        Search
                                    </v-btn>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex justify-content-start mt-3">
                            <v-btn class="mr-2" color="teal" elevation="2" small rounded @click="setMapStyle('mapbox://styles/mapbox/streets-v11')" dark>
                                Streets
                            </v-btn>
                            <v-btn class="mr-2" color="teal" elevation="2" small rounded @click="setMapStyle('mapbox://styles/mapbox/satellite-v9')" dark>
                                Satellite
                            </v-btn>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-12">
                <div class="card elevation-5">
                    <div class="card-body">
                        <div id="mapContainer" style="width: 100%; height: 700px;"></div>
                    </div>
                </div>
            </div>
            
        </div>

    </div>
    
</template>

<script>

import mapboxgl, { Marker, Popup } from 'mapbox-gl';

export default {
    data() {
        return {
            breadcumbs: [
                {
                    text: 'E-Kencana',
                    disabled: false,
                    href: '/admin/kmb',
                },
                {
                    text: 'Dashboard',
                    disabled: false,
                    href: '#',
                },
                {
                    text: 'Customer GIS Analysis',
                    disabled: true,
                    href: '#',
                },
            ],
            accessToken: 'pk.eyJ1IjoiaXQtdml2YSIsImEiOiJjbGlsN3hpZmMwZWJnM2VtbW1td3VpcnQ0In0.UKADhbm0cwuePXLF3pJJQA',
            map: null,
            location: {
                lng: 112.427428,
                lat: -7.515350,
                zoom: 6,
            },
            officeLists: [],
            officeModel: '',
            provinceLists: [],
            provinceModel: '',
            cityLists: [],
            cityModel: '',
            districtLists: [],
            districtModel: '',
            mapMarker: null,
            mapPopup: null,
            popUpCustomerId: '',
            popUpCustomerName: '',
            popUpCustomerAddress: '',
            popUpCustomerDistance: '',
            popUpCustomerOfficeSales: '',
            popUpCustomerLastVisitSales: '',
            popUpCustomerLastVisitDate: '',
            popUpCustomerLon: '',
            popUpCustomerLat: '',
            customerResult: []
        }
    },
    async mounted(){

        this.initialize()
        window.startNavigation = this.startNavigation.bind(this)

    },
    methods:{
        
        async initialize() {

            this.$store.dispatch('setOverlay', true)

            this.initMap()

            await axios.get(`${process.env.VUE_APP_URL}/api/kmb/CustGisAnalysis`, { 
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {                

                this.$store.dispatch('setOverlay', false)
                this.officeLists = res.data.office
                this.cityLists = res.data.city
                this.provinceLists = res.data.province
                this.districtLists = res.data.district

            })
            .catch(err => {
                
                this.$store.dispatch('setOverlay', false)
                console.log(err)
                
            })

        },

        async initMap(){

            mapboxgl.accessToken = this.accessToken;

            this.map = new mapboxgl.Map({
                container: 'mapContainer',
                style: 'mapbox://styles/mapbox/streets-v11',
                center: [this.location.lng, this.location.lat],
                zoom: this.location.zoom,
            });

        },

        setMapStyle(style) {
            if (this.map) {
                this.map.setStyle(style)
            }
        },

        async getData() {
            this.$store.dispatch('setOverlay', true)

            await axios.post(`${process.env.VUE_APP_URL}/api/kmb/CustGisAnalysis/getData`, {
                office: this.officeModel.office_id ? this.officeModel.office_id : '',
                province: this.provinceModel ? this.provinceModel : '',
                city: this.cityModel ? this.cityModel : '',
                district: this.districtModel ? this.districtModel : '',
            },
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser }`
                } 
            })
            .then(res => {
                this.$store.dispatch('setOverlay', false)
                
                if (this.mapMarkers) {
                    this.mapMarkers.forEach(marker => marker.remove());
                }

                this.mapMarkers = []

                this.map.setZoom(10)

                const officeData = res.data.office
                officeData.forEach(office => {
                    const officeMarker = new mapboxgl.Marker()
                        .setLngLat([office.lng, office.lat])
                        .addTo(this.map);

                    const officePopup = new mapboxgl.Popup({ offset: 25 })
                        .setHTML(`<b>Branch Office : ${office.office} (${office.office_id})</b><br>
                        Branch Office Head : ${office.NamaSales} (${office.SalesId})<br>
                        Address : ${office.address}`)

                    officeMarker.setPopup(officePopup)
                    this.mapMarkers.push(officeMarker)
                });

                this.customerResult = res.data.result

                const resultExample = res.data.result

                resultExample.forEach(customer => {
                    
                    const customerMarkerElement = document.createElement('div')
                    customerMarkerElement.className = 'custom-customer-marker'
                    customerMarkerElement.style.width = '10px'
                    customerMarkerElement.style.height = '10px'
                    customerMarkerElement.style.backgroundColor = 'red'
                    customerMarkerElement.style.borderRadius = '50%'
                    customerMarkerElement.style.cursor = 'pointer'

                    const customerMarker = new mapboxgl.Marker(customerMarkerElement)
                        .setLngLat([customer.longitude, customer.latitude])
                        .addTo(this.map)

                    // const popup = new mapboxgl.Popup({ offset: 25 })
                    //     .setHTML(`<b>${customer.NamaCustomer} (${customer.CustomerId})</b><br>
                    //     ${customer.Alamat}<br>Office : ${customer.office_from}<br>
                    //     Distance From Office : ${customer.distance} KM<br>
                    //     Last Visit By : ${customer.last_visit_sales} <br>
                    //     Last Visit Date : ${customer.last_visit} <br>
                    //     <div class="text-center"><button class="btn btn-info" onclick="startNavigation(${this.officeModel.lng}, ${this.officeModel.lat}, ${customer.longitude}, ${customer.latitude})">Navigate</button></div>
                    // `)

                    // const popup = new mapboxgl.Popup({ offset: 25 })
                    //     .setHTML(`<b>${this.popUpCustomerName} (${this.popUpCustomerId})</b><br>
                    //             ${this.popUpCustomerAddress}<br>
                    //             Last Visit By : ${this.popUpCustomerLastVisitSales} <br>
                    //             Sales Office : ${this.popUpCustomerOfficeSales}<br>
                    //             Last Visit Date : ${this.popUpCustomerLastVisitDate} <br>
                    //             Distance From Office : ${this.popUpCustomerDistance} KM<br>
                    //             <div class="text-center"><button class="btn btn-info" onclick="startNavigation(${this.officeModel.lng}, ${this.officeModel.lat}, ${this.popUpCustomerLon}, ${this.popUpCustomerLat})">Navigate</button></div>`)

                    customerMarker.getElement().addEventListener('click', () => {
                        this.getPopUpDetail(customer.CustomerId);
                    });

                    // console.log('lng : ' + this.officeModel.lng + ' lat : ' + this.officeModel.lat)                    

                    // customerMarker.setPopup(popup)
                    this.mapMarkers.push(customerMarker)
                });

            })
            .catch(err => {
                this.$store.dispatch('setOverlay', false)
                console.log(err)
            });
        },

        async getPopUpDetail(id) {
            // console.log(id);

            this.$store.dispatch('setOverlay', true)

            await axios.post(`${process.env.VUE_APP_URL}/api/kmb/CustGisAnalysis/getCustDetail`, { 
                customerid: id,
            },
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser }`
                } 
            })
            .then(res => {
                this.$store.dispatch('setOverlay', false)

                const customer = res.data.customer

                const customerMarkerElement = document.createElement('div')
                customerMarkerElement.className = 'custom-customer-marker'
                customerMarkerElement.style.width = '10px'
                customerMarkerElement.style.height = '10px'
                customerMarkerElement.style.backgroundColor = 'red'
                customerMarkerElement.style.borderRadius = '50%'
                customerMarkerElement.style.cursor = 'pointer'

                const customerMarker = new mapboxgl.Marker(customerMarkerElement)
                    .setLngLat([customer.longitude, customer.latitude])
                    .addTo(this.map)

                const popup = new mapboxgl.Popup({ offset: 25 })
                    .setHTML(`<b>${res.data.customer.NamaCustomer} (${res.data.customer.CustomerId})</b><br>
                            ${res.data.customer.Alamat}<br>
                            Last Visit By: ${res.data.last_visit.NamaSales}<br>
                            Last Visit Date: ${res.data.last_visit.tr_date}<br>
                            Office: ${res.data.last_visit.office} (${res.data.distance_office} KM)<br>
                            <div class="text-center"><button class="btn btn-info" onclick="startNavigation(${res.data.last_visit.lng}, ${res.data.last_visit.lat}, ${res.data.customer.longitude}, ${res.data.customer.latitude})">Navigate</button></div>`)

                customerMarker.setPopup(popup).togglePopup()

                this.map.flyTo({
                    center: [customer.longitude, customer.latitude],
                    zoom: 12
                })
            })
            .catch(err => {
                this.$store.dispatch('setOverlay', false)
                console.log(err)
            })
        },

        startNavigation(fromLon, fromLat, destLon, destLat) {
            
            if (this.map.getLayer('route')) {
                this.map.removeLayer('route')
            }
            if (this.map.getSource('route')) {
                this.map.removeSource('route')
            }

            navigator.geolocation.getCurrentPosition((position) => {
                const directionsUrl = `https://api.mapbox.com/directions/v5/mapbox/driving/${fromLon},${fromLat}${destLon},${destLat}?access_token=${this.accessToken}&geometries=geojson`

                fetch(directionsUrl)
                    .then(response => response.json())
                    .then(data => {
                        const route = data.routes[0].geometry.coordinates

                        this.map.addSource('route', {
                            type: 'geojson',
                            data: {
                                type: 'Feature',
                                geometry: {
                                    type: 'LineString',
                                    coordinates: route
                                }
                            }
                        })

                        this.map.addLayer({
                            id: 'route',
                            type: 'line',
                            source: 'route',
                            layout: {
                                'line-join': 'round',
                                'line-cap': 'round'
                            },
                            paint: {
                                'line-color': '#3486eb',
                                'line-width': 6
                            }
                        })

                        const bounds = [
                            [Math.min(fromLon, destLon), Math.min(fromLat, destLat)],
                            [Math.max(fromLon, destLon), Math.max(fromLat, destLat)]
                        ]
                        this.map.fitBounds(bounds, { padding: 20 })

                        const radius = 0.5 // in kilometers
                        const markersWithinRadius = []

                        const haversineDistance = (lon1, lat1, lon2, lat2) => {
                            const R = 6371
                            const dLat = (lat2 - lat1) * (Math.PI / 180)
                            const dLon = (lon2 - lon1) * (Math.PI / 180)
                            const a =
                                Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                                Math.cos(lat1 * (Math.PI / 180)) * Math.cos(lat2 * (Math.PI / 180)) *
                                Math.sin(dLon / 2) * Math.sin(dLon / 2)
                            const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
                            return R * c
                        }

                        this.customerResult.forEach(customer => {
                            const distance = haversineDistance(fromLon, fromLat, customer.longitude, customer.latitude)
                            if (distance <= radius) {
                                markersWithinRadius.push(customer)
                                console.log('Marker within 0.5 km:', customer.NamaCustomer)
                            }
                        })
                        

                    })
                    .catch(err => console.error(err))
            })
        },

        async provinceChange(id){

            if(id){

                this.$store.dispatch('setOverlay', true)

                await axios.post(`${process.env.VUE_APP_URL}/api/kmb/CustGisAnalysis/provinceChange`, { 
                    province: id,
                },
                {
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Access-Control-Allow-Origin': '*',
                            Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                        } 
                })
                .then(res => {
                    this.$store.dispatch('setOverlay', false)
                    this.cityLists = res.data.result
                })
                .catch(err => {

                    this.$store.dispatch('setOverlay', false)
                    console.log(err)
                    
                })

            }
            
        },

        async cityChange(id){

            if(id){

                this.$store.dispatch('setOverlay', true)

                await axios.post(`${process.env.VUE_APP_URL}/api/kmb/CustGisAnalysis/cityChange`, { 
                    city: id,
                },
                {
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Access-Control-Allow-Origin': '*',
                            Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                        } 
                })
                .then(res => {
                    this.$store.dispatch('setOverlay', false)
                    this.districtLists = res.data.result
                })
                .catch(err => {

                    this.$store.dispatch('setOverlay', false)
                    console.log(err)
                    
                })

            }
            
        },
        
        async clearAll(){

            if (this.mapMarkers) {
                this.mapMarkers.forEach(marker => marker.remove())
                this.mapMarkers = []
            }

            if (this.map.getLayer('route')) {
                this.map.removeLayer('route')
            }
            if (this.map.getSource('route')) {
                this.map.removeSource('route')
            }

            this.officeModel = ''
            this.provinceModel = ''
            this.cityModel = ''
            this.districtModel = ''

        }

    },
    watch: {

        
    }
    
}
</script>

<style scoped>

    .v-text-field {
        padding-top: 10px !important;
    }

    .v-breadcrumbs {
        padding: 5px 5px !important;
    }

    .badgeInfo {
        font-size: 1em;
        padding-bottom: 10px; 
        padding-left: 10px;
    }

    .cardCustom{
        padding-top: 10px;
    }

    .container{
        margin-bottom: 100px !important;
    }

    .v-text-field >>> input {
        font-size: 1em !important;
        font-weight: 100 !important;
        text-transform: capitalize !important;
    }
    .v-text-field >>> label {
        font-size: 0.8em !important;
    }
    .v-text-field >>> button {
        font-size: 0.8em !important;
    }

    .modal {
        margin: 0 auto; 
    }
    
    .custMarker {
        background-image: url('https://docs.mapbox.com/demos/custom-markers-gl-js/mapbox-icon.png');
        background-size: cover;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        cursor: pointer;
    }

    .custom-office-marker {
        width: 10px; /* Adjust to your desired size */
        height: 10px; /* Adjust to your desired size */
        background-color: red; /* Set the color to red */
        border-radius: 50%; /* Make it circular */
        cursor: pointer; /* Change cursor to pointer */
    }

</style>